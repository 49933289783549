



























import Vue from "vue";
import Component from "vue-class-component";
import AuthenticationForm from "../components/AuthenticationForm.vue";

@Component({ components: { AuthenticationForm } })
export default class Authentication extends Vue {
  get columnWidth(): number {
    return this.useExpandedLogin ? 6 : 12;
  }

  get cardClasses(): string {
    return this.useExpandedLogin ? "border-0" : "auth-card";
  }

  get useExpandedLogin(): boolean {
    return this.$store.getters["Features/expandedLogin"];
  }
}
