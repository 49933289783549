




































































































import Vue from "vue";
import Component from "vue-class-component";

@Component({ components: {} })
export default class Authentication extends Vue {
  user = { email: "", first_name: "", last_name: "", code: "", role: "" };
  submitted = false;
  successful = false;
  accepted = false;
  message = "";
  loading = false;

  get getLangConcat(): string {
    var lang = localStorage.getItem("lang") || "";
    return lang.split("_").shift() || "";
  }

  get isLoggedIn(): boolean {
    return this.$store.getters["Auth/isLoggedIn"];
  }

  get authenticationType(): string {
    return this.$store.getters["Features/authenticationType"];
  }

  get showEmail() {
    return this.authenticationType !== "only-code";
  }

  get showFirstname() {
    return (
      this.authenticationType === "basic" ||
      this.authenticationType === "global-code"
    );
  }

  get showLastname() {
    return (
      this.authenticationType === "basic" ||
      this.authenticationType === "global-code"
    );
  }

  get showCode() {
    return (
      this.authenticationType === "code" ||
      this.authenticationType === "only-code" ||
      this.authenticationType === "global-code"
    );
  }

  mounted(): void {
    this.loading = true;
    if (this.isLoggedIn == true) {
      this.redirectAfterLogin();
    } else if (this.$route.query.email && this.$route.query.access_code) {
      this.user.email = atob(this.$route.query.email as string);
      this.user.code = atob(this.$route.query.access_code as string);
      this.handleAuthentication();
    } else {
      this.loading = false;
    }
  }

  async created(): Promise<void> {
    const lang = this.getLangConcat;
    const validateLanguage = await import("vee-validate/dist/locale/" + lang);

    this.$validator.localize(lang as string, {
      messages: validateLanguage.default.messages,
      attributes: {
        email: this.translateToLowerCase("email"),
        first_name: this.translateToLowerCase("firstname"),
        last_name: this.translateToLowerCase("lastname"),
        terms: this.translateToLowerCase("privacyPolicy"),
      },
    });
  }

  translateToLowerCase(key: string): string {
    return (this.$t(key) as string).toLowerCase();
  }

  redirectAfterLogin(): void {
    if (!this.$route.query.redirect) {
      this.$router.push("/");
      return;
    }
    const redirectSplitted = (this.$route.query.redirect as string).split(":");
    if (redirectSplitted.length > 2 || redirectSplitted.length < 1) {
      this.$router.push("/");
      return;
    }
    const route = { name: redirectSplitted[0], params: {} };
    if (redirectSplitted.length === 2) {
      route.params = { id: redirectSplitted[1] };
    }
    this.$router.push(route);
  }

  async handleAuthentication(): Promise<void> {
    this.user.role = "user";
    this.message = "";
    this.submitted = true;
    const isValid = await this.$validator.validateAll();
    if (!isValid && !this.$route.query.access_code) {
      this.loading = false;
      return;
    }

    this.$store.dispatch("Auth/login", this.user).then(
      (data) => {
        if (!data.succesful) this.message = data.error;
        this.redirectAfterLogin();
      },
      (error) => {
        this.loading = false;
        this.message = error.response.data.error;
      }
    );
  }
}
